@font-face {
    font-family: "MyComicSans";
    src: url(./font/COMIC.TTF), format("truetype");
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f8e932;
    font-family: "MyComicSans";
}
